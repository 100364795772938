export function logEnv() {
  if (isDevo()) {
    console.log(
      "Add devo settings to `.env.development.local` with lines like:"
    );
    console.log("REACT_APP_AUTH_TOKEN=<credential>");
    console.log("NPM caches these settings so restart to see updates.");
    const authToken = getDevAuthorization();
    if (authToken) {
      console.log(
        "Automatically logged in for local development. Token (truncated): " +
          authToken.substring(0, 10) +
          "..."
      );
    } else {
      console.log(
        "Set the REACT_APP_AUTH_TOKEN setting will automatically log in your devo instance of NPM."
      );
    }
    console.log("Arguments: " + JSON.stringify(process.env));
  }
}

export function isDevo(): boolean {
  return process.env.NODE_ENV === "development";
}

export function getDevAuthorization(): string | undefined {
  return isDevo() ? process.env.REACT_APP_AUTH_TOKEN : undefined;
}
