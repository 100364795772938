import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { isDevo, logEnv } from "./environment";

logEnv();
const root = ReactDOM.createRoot(document.getElementById("root"));
const googleOAuthClientId = isDevo()
  ? // Devo client Id which allows localhost to use it.
    "1044193743137-k7hhp1cu9lh4fdrc1fvb5cii249foe6g.apps.googleusercontent.com"
  : // Prod client Id which can only be used on the Stronghold domain. TODO: This is actually a temp client. Switch to a permanent one.
    "1044193743137-k7hhp1cu9lh4fdrc1fvb5cii249foe6g.apps.googleusercontent.com";
root.render(
  <React.StrictMode>
    {/* TODO: Make Client Id environment specific. In devo us this (Stronghold Dev). In prod use the other one. */}
    <GoogleOAuthProvider clientId={googleOAuthClientId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
