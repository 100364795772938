import LoggedInPageChrome from "../logged-in-page-chrome/LoggedInPageChrome";
import LoginPage from "../login-page/LoginPage";
import React from "react";

type LoginGatedPageProps = {
  children: React.ReactNode;
  loggedIn: boolean;
  setLoginToken: (token: string | undefined) => void;
};

function LoginGatedPage(props: LoginGatedPageProps) {
  console.log("Is logged in " + props.loggedIn);
  return props.loggedIn ? (
    <LoggedInPageChrome setLoginToken={props.setLoginToken}>
      {props.children}
    </LoggedInPageChrome>
  ) : (
    <LoginPage {...props} />
  );
}

export default LoginGatedPage;
