export function listDevices(loginToken: string): Promise<any> {
  console.log("Listing Devices.");
  return fetch("https://api.strongholdunlimited.com/devices/mine", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + loginToken,
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((response: Response) => {
    if (response.ok) {
      return response.json();
    }
    const error: Error = {
      name: "Device Listing Failed",
      message: "Unable to enumerate your devices",
    };
    return Promise.reject(error);
  });
}

export function provisionDevice(loginToken: string) {
  console.log("Provisioning Device.");
  return fetch("https://api.strongholdunlimited.com/devices/mine", {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + loginToken,
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((response: Response) => {
    if (response.ok) {
      return response.json();
    }
    const error: Error = {
      name: "Provisioning Failed",
      message: "Unable to provision a new device for unknown reason",
    };
    return Promise.reject(error);
  });
}
