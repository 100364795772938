import { isDevo } from "../../environment";
import { useState } from "react";
import Toast from "react-bootstrap/Toast";
import { GoogleLogin } from "@react-oauth/google";

export interface LoginPageProps {
  setLoginToken: (token: string | undefined) => void;
}

function LoginPage(props: LoginPageProps) {
  const [loginError, setLoginError] = useState(false);
  const dismissLoginError = () => setLoginError(false);
  return (
    <>
      {isDevo() && <h1>Development Environment</h1>}
      <h2>TODO- Logo goes here.</h2>
      <Toast show={loginError} onClose={dismissLoginError}>
        <Toast.Header>Login Failed</Toast.Header>
      </Toast>
      {
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            setLoginError(false);
            props.setLoginToken(credentialResponse.credential);
            console.log(credentialResponse);
          }}
          onError={() => {
            setLoginError(true);
            console.log("Login Failed");
          }}
        />
      }
      <p>
        Contact us at{" "}
        <a href="mailto:info@strongholdunlimited.com">
          info@strongholdunlimited.com
        </a>
        .
      </p>
    </>
  );
}

export default LoginPage;
