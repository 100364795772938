import { useState, useEffect } from "react";
import ProvisionDevicePage from "../provision-device-workflow/ProvisionDevicePage";
import { listDevices } from "../../apis/DeviceManagement";

type DevicesHomePageProps = {
  loginToken: string;
};

function DevicesHomePage(props: DevicesHomePageProps) {
  const [devices, setDevices] = useState(undefined);
  // TODO: 'fetching' reduces repeated calls, but we're still calling multiple times.
  // Move device data management into a context that provides semantic APIs which don't duplicate.
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (!fetching) {
      setFetching(true);
      listDevices(props.loginToken).then((response: any) =>
        setDevices(response)
      );
    }
  }, [fetching, props.loginToken]);
  return (
    <>
      <div>Devices Home Page</div>
      {devices === undefined ? "loading devices" : JSON.stringify(devices)}
      <ProvisionDevicePage loginToken={props.loginToken} />
    </>
  );
}

export default DevicesHomePage;
