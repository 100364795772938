import { useState } from "react";
import React from "react";
import LoginGatedPage from "./components/login-gated-page/LoginGatedPage";
import DevicesHomePage from "./components/devices-home-page/DevicesHomePage";
import { getDevAuthorization } from "./environment";

function App() {
  const [loginToken, setLoginToken] = useState(getDevAuthorization());
  return (
    <LoginGatedPage
      loggedIn={loginToken !== undefined}
      setLoginToken={setLoginToken}
    >
      {loginToken && <DevicesHomePage loginToken={loginToken} />}
    </LoginGatedPage>
  );
}

export default App;
