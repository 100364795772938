import React from "react";
import { isDevo } from "../../environment";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { googleLogout } from "@react-oauth/google";
import { Button } from "react-bootstrap";

type LoggedInPageChromeProps = {
  children: React.ReactNode;
  setLoginToken: (token: string | undefined) => void;
};

function LoggedInPageChrome(props: LoggedInPageChromeProps) {
  return (
    <>
      <Navbar bg="light" expand="lg">
        {
          // Scrappy way to make sure Prod and Devo can be distinguished in a screenshot.
        }
        <Navbar.Brand>Stronghold{isDevo() ? " (Devo)" : ""}</Navbar.Brand>
        <Nav className="ml-auto">
          <Button
            onClick={() => {
              googleLogout();
              props.setLoginToken(undefined);
            }}
          >
            Sign Out
          </Button>
        </Nav>
      </Navbar>
      {props.children}
      <div>
        Contact us at{" "}
        <a href="mailto:info@strongholdunlimited.com">
          info@strongholdunlimited.com
        </a>
      </div>
    </>
  );
}

export default LoggedInPageChrome;
