import { useEffect, useState } from "react";
import { provisionDevice } from "../../apis/DeviceManagement";

type ProvisionDevicePageProps = {
  loginToken: string;
};

function ProvisionDevicePage(props: ProvisionDevicePageProps) {
  const [provisioningToken, setProvisioningToken] = useState(undefined);
  // TODO: 'fetching' is a bad solution. See DevicesHomePage which has the same problem.
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (!fetching) {
      setFetching(true);
      provisionDevice(props.loginToken).then((json: any) =>
        setProvisioningToken(json)
      );
    }
  }, [fetching, props.loginToken]);
  return (
    <>
      <div> Device provisioning page. Token: {props.loginToken} </div>
      <div>
        {provisioningToken === undefined
          ? "provisioning..."
          : "provisioned:" + JSON.stringify(provisioningToken)}
      </div>
    </>
  );
}

export default ProvisionDevicePage;
